<template>
    <modal-dialog
        id="listing-creation-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span v-if="current_tab_id === 'cma'" class="h5">{{ $root.translate("New CMA") }}</span>
            <span v-else class="h5">{{ $root.translate("New Listing") }}</span>
        </template>
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            @submit.prevent.stop="save"
        >
            <tabs
                :tabs="[ { id: 'full', title: translate('Full Listing') }, { id: 'cma', title: translate('CMA') } ]"
                :current-tab-id="current_tab_id"
                @click="change_type"
            >
                <div class="columns">
                    <div class="column col-sm-12">
                        <form-input
                            id="price"
                            name="price"
                            v-model="edited_record.price"
                            type="stripe"
                            glyph="dollar-sign"
                            glyph-position="left"
                            :min="1"
                            :required="true"
                            :disabled="creating_record"
                            :error="has_error('price')"
                        >
                            <template v-slot:label>
                                <span v-if="edited_record.on_market_on">{{ translate("Asking Price") }}</span>
                                <span v-else>{{ translate("Evaluation Price") }}</span>
                            </template>
                        </form-input>
                    </div>
                    <div v-if="!!edited_record.on_market_on" class="column col-sm-12">
                        <form-date-input
                            id="on_market_on"
                            name="on_market_on"
                            v-model="edited_record.on_market_on"
                            :required="false"
                            :disabled="creating_record"
                        >
                            <template v-slot:label>
                                {{ translate("Listed on") }}
                            </template>
                        </form-date-input>
                    </div>
                </div>

                <address-input
                    id="address"
                    name="address"
                    :value="edited_record"
                    :fixed-country="true"
                    :required="true"
                    :disabled="creating_record"
                    :errors="errors"
                >
                    <template v-slot:label>
                        {{ translate("Address") }}
                    </template>
                </address-input>
                <form-multi-select
                    id="neighborhood_id"
                    name="neighborhood_id"
                    :value="edited_record.neighborhood_id ? [edited_record.neighborhood_id] : []"
                    :options="neighborhoods"
                    display="chips"
                    :required="true"
                    :disabled="creating_record"
                    :max-selection="1"
                    :error="has_error('neighborhood_id')"
                    @input="save_neighborhood"
                >
                    <template v-slot:label>
                        {{ translate("Neighborhood") }}
                    </template>
                </form-multi-select>
            </tabs>
            <div class="text-center mt-8">
                <default-button
                    class="mx-2"
                    :disabled="creating_record"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    color="primary"
                    :waiting="creating_record"
                    :disabled="creating_record"
                    class="mx-2"
                >
                    {{ $root.translate("Create") }}
                </default-button>
            </div>
        </base-form>
    </modal-dialog>
</template>

<script>
import is_record_editor from "@/nibnut/mixins/IsRecordEditor"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import Tabs from "@/nibnut/components/Containers/Tabs"
import FormDateInput from "@/nibnut/components/Inputs/FormDateInput"
import AddressInput from "@/nibnut/components/Inputs/AddressInput/AddressInput"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FormMultiSelect from "@/custom/components/FormMultiSelect"

export default {
    name: "ListingCreationDialog",
    mixins: [is_record_editor, addl_misc_utilities],
    components: {
        BaseForm,
        Tabs,
        FormDateInput,
        FormInput,
        AddressInput,
        FormMultiSelect,
        ModalDialog,
        DefaultButton
    },
    watch: {
        show: "reload"
    },
    methods: {
        grab_record_id () {
            this.record_id = 0
        },
        post_load () {
            this.load_vocabularies([this.constants("vocabularies", "VOCABULARY_NEIGHBORHOODS").id])
        },
        change_type (type) {
            this.current_tab_id = type
            this.edited_record.on_market_on = (type === "cma") ? null : this.$dayjs().format("YYYY-MM-DD")
        },
        save_neighborhood (value, field) {
            if(!!value && !!value.length) value = value[0]
            else if(!value.length) value = 0
            this.edited_record[field] = value
        },
        save () {
            const edited_record = this.edited_record
            this.creating_record = true
            return this.save_data_for_record_id(this.entity, 0, edited_record).then(record => {
                this.creating_record = false
                if(!!record && !this.has_error()) {
                    this.shown = false
                    this.$router.replace({ name: `${this.entity}.edit`, params: { id: record.uuid || record.id } })
                }
            })
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        can_reload () {
            return this.show && (this.is_public_page || this.profile_id)
        },
        cleanup_before_reload () {
            return false
        },
        neighborhoods () {
            return this.vocabulary("VOCABULARY_NEIGHBORHOODS")
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "listing",
            entityName: "listing:::listings",
            current_tab_id: "full"
        }
    }
}
</script>
