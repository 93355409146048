<template>
    <modal-dialog
        v-if="!!record && !!record.id"
        id="event-viewer-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span v-if="!!record.executed_on" class="h5">
                {{ record.name }}
                &nbsp;&bull;&nbsp;
                {{ record.executed_on | nibnut.date("YYYY-MM-DD") }}
            </span>
            <span v-else class="h5">
                {{ $root.translate("Showing") }}
                <span v-if="is_past">
                    &nbsp;&bull;&nbsp;
                    {{ record.starts_at | nibnut.date("YYYY-MM-DD") }}, {{ record.starts_at | nibnut.date("HH:mm") }} to {{ record.ends_at | nibnut.date("HH:mm") }}
                </span>
            </span>
        </template>

        <div v-if="!!record.description">
            {{ record.description }}
        </div>
        <div v-else>
            <h6>{{ translate("Feedback") }}</h6>
            <p>{{ feedback.comments }}</p>
        </div>
    </modal-dialog>
</template>

<script>
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"

export default {
    name: "EventViewerDialog",
    mixins: [addl_profile_utilities],
    components: {
        ModalDialog
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        is_past () {
            if(this.record) {
                const now = new Date()
                if(this.record._entity === "listing_action") return !!this.record.executed_on && this.$dayjs(this.record.executed_on).isSameOrBefore(now, "minute")
                return !!this.record.starts_at && this.$dayjs(this.record.starts_at).isSameOrBefore(now, "minute")
            }
            return false
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        record: {
            type: Object,
            required: true
        },
        feedback: {
            type: Object,
            default () {
                return {}
            }
        }
    }
}
</script>
