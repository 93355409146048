<template>
    <modal-dialog
        id="destination-dialog"
        :show.sync="shown"
        :dismissable="true"
        class="modal-lg"
    >
        <template v-slot:title>{{ title }}</template>
        <PortalTarget name="destination-modal-destination"></PortalTarget>
    </modal-dialog>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import { PortalTarget } from "portal-vue"

export default {
    name: "DestinationModal",
    components: {
        ModalDialog,
        PortalTarget
    },
    computed: {
        shown: {
            get () {
                return !!this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            default: false
        },
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";
</style>
