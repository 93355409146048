<template>
    <modal-dialog
        id="showing-editor-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span v-if="!!record && !!record.id" class="h5">
                {{ $root.translate("Showing") }}
                <span v-if="is_past">
                    &nbsp;&bull;&nbsp;
                    {{ record.starts_at | nibnut.date("YYYY-MM-DD") }}, {{ record.starts_at | nibnut.date("HH:mm") }} to {{ record.ends_at | nibnut.date("HH:mm") }}
                </span>
            </span>
            <span v-else class="h5">{{ $root.translate("New Showing") }}</span>
        </template>
        <showing-editor
            id="showing-editor"
            :record="record"
            :with-calendar="false"
            @submit="create"
            @saved="$emit('saved')"
        />
        <div v-if="can_set_feedback" class="my-4">
            <div
                v-if="!is_at_least_realtor && ((!feedback.ratings && !feedback.comments) || !feedback.published_at)"
                class="text-gray"
            >
                {{ translate("No Feedback Provided") }}
            </div>
            <div v-else>
                <h6>{{ translate("Feedback") }}</h6>
                <div v-if="feedback.ratings && is_at_least_realtor" class="columns mb-2">
                    <feedback-rating
                        v-for="(rating, label) in feedback.ratings"
                        :key="label"
                        :label="label"
                        :rating="rating"
                        class="column col-6 col-sm-12"
                    />
                </div>
                <div v-if="feedback.comments && !is_at_least_realtor">
                    <label class="form-label">{{ translate("Comments") }}</label>
                    <p>{{ feedback.comments }}</p>
                </div>
                <form-textbox
                    v-else-if="is_at_least_realtor"
                    id="comments"
                    name="comments"
                    v-model="feedback.comments"
                    size="md"
                    :required="true"
                    @input="save_feedback"
                >
                    <template v-slot:label>
                        {{ $root.translate("Comments") }}
                    </template>
                    <template
                        v-slot:hint
                        v-if="!!feedback.original_comments && (feedback.original_comments !== feedback.comments)"
                    >
                        {{ feedback.original_comments }}
                    </template>
                </form-textbox>
                <form-toggle-input
                    v-if="is_at_least_realtor"
                    id="published_at"
                    name="published_at"
                    type="checkbox"
                    :value="!!feedback.published_at"
                    :editable="true"
                    :required="false"
                    class="mt-2"
                    @input="toggle_published_at"
                >
                    {{ translate("Published") }}
                </form-toggle-input>
            </div>
        </div>
        <template v-if="!!record && !record.id" v-slot:footer>
            <div class="text-center">
                <default-button
                    class="mx-2"
                    @click.prevent="shown = false"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    form="showing-editor"
                    color="primary"
                    class="mx-2"
                >
                    {{ translate("Create") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import ShowingEditor from "@/custom/components/ShowingEditor"
import FeedbackRating from "@/custom/components/FeedbackRating"

export default {
    name: "ShowingEditorDialog",
    mixins: [addl_profile_utilities],
    components: {
        ModalDialog,
        FormTextbox,
        FormToggleInput,
        DefaultButton,
        ShowingEditor,
        FeedbackRating
    },
    watch: {
        show: "reset"
    },
    methods: {
        create () {
            if(this.record && !this.record.id) this.$emit("create")
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        is_past () {
            return !!this.record && !!this.record.starts_at && this.$dayjs(this.record.starts_at).isSameOrBefore(new Date(), "minute")
        },
        can_set_feedback () {
            return this.is_past && !!this.record.confirmed_at && !!this.feedback
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        record: {
            type: Object,
            required: true
        },
        feedback: {
            type: Object,
            default () {
                return {}
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#showing-editor-dialog {
    form {
        transition: all .3s ease;

        .popover.popover-right .popover-container {
            top: 0;
        }

        &.extended {
            padding-top: $unit-12;
            padding-bottom: $unit-12;
        }
    }
}
</style>
