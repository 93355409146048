<template>
    <authentication-panel
        :title="fetching ? translate('Fetching your invitation...') : translate('Signup')"
        @submit="signup"
    >
        <div
            v-if="fetching"
            class="loader-container"
        >
            <loader />
        </div>
        <div v-else class="nibnut-field-group">
            <form-input
                id="first_focusable"
                ref="first_field"
                v-model="email"
                name="email"
                type="email"
                glyph="envelope"
                glyph-position="left"
                :placeholder="$root.translate('Email')"
                :error="has_error('email')"
                :required="true"
                :maybe-autofilled="true"
                @input="update_email"
                @blur="update_email"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("Email") }}</template>
            </form-input>
            <password-editor
                id="password"
                v-model="password"
                type="password"
                name="password"
                glyph="key"
                glyph-position="left"
                autocomplete="new-password"
                :error="has_error('password')"
                :required="true"
                class="pb-4"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("Password") }}</template>
            </password-editor>
            <form-input
                id="first_name"
                v-model="first_name"
                name="first_name"
                glyph="user"
                glyph-position="left"
                :placeholder="$root.translate('First Name')"
                :error="has_error('first_name')"
                :required="false"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("First Name") }}</template>
            </form-input>
            <form-input
                id="last_name"
                v-model="last_name"
                name="last_name"
                glyph="user"
                glyph-position="left"
                :placeholder="$root.translate('Last Name')"
                :error="has_error('last_name')"
                :required="false"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("Last Name") }}</template>
            </form-input>
        </div>
        <div v-if="!fetching" class="text-center mt-8">
            <feedback-button
                type="submit"
                :status="feedback_status"
                :waiting="loading"
                :single-use="false"
                color="primary"
            >
                <template v-slot:message>
                    <div
                        :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                    >
                        <div v-if="!!already_invited" class="toast toast-error text-center">
                            {{ already_invited }}
                            <br />
                            <small><a href @click.prevent="resend_invitation">({{ $root.translate("Please resend me my invitation link...") }})</a></small>
                        </div>
                        <span v-else>{{ feedback_message }}</span>
                    </div>
                </template>
                <open-icon glyph="unlock" />
                {{ $root.translate("Register") }}
            </feedback-button>
        </div>

        <template v-slot:footer>
            <div v-if="!invitation_token" class="text-center">
                {{ translate("Already have an account?") }}
                <base-link
                    href="#"
                    @click.prevent.stop="goto('login')"
                >
                    {{ $root.translate("Login") }}
                </base-link>
            </div>
        </template>
    </authentication-panel>
</template>

<script>
import is_panel from "@/nibnut/dialogs/Authentication/IsPanel"

import AuthenticationPanel from "@/nibnut/dialogs/Authentication/AuthenticationPanel"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import PasswordEditor from "@/nibnut/components/Inputs/PasswordEditor"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"

import Loader from "@/custom/components/Loader"

export default {
    mixins: [is_panel],
    components: {
        AuthenticationPanel,
        FormInput,
        PasswordEditor,
        BaseLink,
        OpenIcon,
        FeedbackButton,
        Loader
    },
    mounted () {
        this.load_invitation()
    },
    methods: {
        load_invitation () {
            if(window.location.search) {
                const matches = window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)
                if(matches) {
                    const token = matches[1]

                    this.fetching = true
                    this.$store.dispatch("LOAD_INVITATION", {
                        token
                    }).then(invitation_data => {
                        if(invitation_data.existing_user) this.goto("login")
                        else {
                            this.invitation_token = token
                            this.first_name = invitation_data.first_name
                            this.last_name = invitation_data.last_name
                            this.email = invitation_data.email
                        }
                    }).catch(this.receive_error).then(() => {
                        this.fetching = false
                    })
                }
            }
        },
        resend_invitation () {
            if(this.email) {
                /*
                this.$store.dispatch("RECORDS_ACTION", { entity: "user", action: "re-invite", data: { email: this.email }, passthru: true }).then(() => {
                    this.$success("<strong>" + this.$root.translate("Invitation link re-sent!") + "</strong><br>" + this.$root.translate("Check your inbox... (or junk folder, just in case)"))
                }).catch(error => {
                    this.$error("<strong>" + error.header + "</strong><br>" + error.message)
                })
                */
            }
        },
        signup () {
            setTimeout(() => {
                const signup = () => {
                    this.loading = true
                    this.clear_all_errors()
                    this.$store.dispatch("SIGNUP", {
                        data: {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            email: this.email,
                            password: encodeURIComponent(this.password),
                            invitation_token: this.invitation_token
                        }
                    }).then(() => {
                        this.$emit("input", "")
                        this.first_name = ""
                        this.last_name = ""
                        this.password = ""
                        this.invitation_token = ""
                        this.$store.dispatch("UNREQUEST_LOGIN")
                        window.location.search = ""
                    }).catch(error => {
                        if(error) {
                            if(error.topic_id && (error.topic_id === "invitation_token")) {
                                this.already_invited = error.message
                            } else this.receive_error(error)
                        }
                    }).then(() => {
                        this.loading = false
                    })
                }
                signup()
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        }
    },
    data () {
        return {
            fetching: false,
            invitation_token: "",
            first_name: "",
            last_name: "",
            password: ""
        }
    }
}
</script>
