<template>
    <modal-dialog
        id="address-editor-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span class="h5">{{ $root.translate("Address Editor") }}</span>
        </template>
        <base-form
            :has-required-fields="true"
            @submit.prevent.stop="save"
        >
            <address-input
                :id="`address-editor-${record.id}`"
                name="address"
                v-model="address"
                :fixed-country="true"
                :required="false"
            />

            <div class="text-center">
                <default-button
                    class="mx-2"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    class="mx-2"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </base-form>
    </modal-dialog>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import AddressInput from "@/nibnut/components/Inputs/AddressInput/AddressInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

const copy_properties = (from, to) => {
    ["line1", "line2", "city", "state", "zip", "country"].forEach(field => {
        to[field] = from[field]
    })
}

export default {
    components: {
        BaseForm,
        ModalDialog,
        AddressInput,
        DefaultButton
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            copy_properties(this.record, this.address)
        },
        save () {
            copy_properties(this.address, this.record)
            this.shown = false
            this.$emit("input")
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            address: {
                line1: "",
                line2: "",
                city: "",
                state: "",
                zip: "",
                country: ""
            }
        }
    }
}
</script>
