<template>
    <modal-dialog
        id="send-listings-dialog"
        :show.sync="shown"
        :dismissable="!sending"
    >
        <template v-slot:title>
            <span v-if="!!listingIds.length" class="h5">{{ $root.translate("Send 1 Listing:::Send {n} Listings", { n: listingIds.length }, listingIds.length) }}</span>
            <span v-else class="h5">{{ $root.translate("Send Listings") }}</span>
        </template>
        <base-form
            :has-required-fields="true"
            @submit.prevent.stop="send"
        >
            <form-tag-input
                id="to"
                name="to"
                v-model="to"
                label-field="label"
                data-source="user"
                :required="true"
            >
                <template v-slot:label>{{ $root.translate("To") }}:</template>
                <template v-slot:selection="{ selected_options }">
                    <div
                        v-for="option in selected_options"
                        :key="option.id"
                        class="chip"
                    >
                        {{ option.name }}
                        <a
                            href
                            class="btn btn-clear"
                            aria-label="Close"
                            role="button"
                            @click.prevent="remove_email(option.id)"
                        ></a>
                    </div>
                </template>
            </form-tag-input>
            <form-input
                id="subject"
                name="subject"
                v-model="subject"
                :required="true"
            >
                <template v-slot:label>{{ $root.translate("Subject") }}</template>
            </form-input>
            <form-editor
                id="message"
                :data-version="data_version"
                name="message"
                v-model="message"
                :required="true"
            >
                <template v-slot:label>{{ $root.translate("Message") }}</template>
            </form-editor>

            <div class="text-center">
                <default-button
                    class="mx-2"
                    :disabled="sending"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    :waiting="sending"
                    :disabled="sending || !subject || !message || !to.length"
                    class="mx-2"
                >
                    {{ $root.translate("Send") }}
                </default-button>
            </div>
        </base-form>
    </modal-dialog>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import FormTagInput from "@/nibnut/components/Inputs/FormTagInput"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormEditor from "@/nibnut/components/Inputs/FormEditor"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "SendListingsDialog",
    mixins: [handles_errors],
    components: {
        BaseForm,
        ModalDialog,
        FormTagInput,
        FormInput,
        FormEditor,
        DefaultButton
    },
    watch: {
        shown: "reset",
        contacts: "reset_contacts"
    },
    methods: {
        reset () {
            this.data_version += 1
            this.subject = ""
            this.message = ""
            this.reset_contacts()
        },
        reset_contacts () {
            this.to = this.contacts || []
        },
        remove_email (id) {
            const index = this.to.indexOf(id)
            if(index >= 0) this.to.splice(index, 1)
        },
        send () {
            this.sending = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "user",
                    action: "send-listings",
                    data: {
                        to: this.to,
                        subject: this.subject,
                        message: this.message,
                        listing_ids: this.listingIds
                    },
                    passthru: true,
                    method: "post"
                }
            ).then(response => {
                if(response.message) this.$success(response.message)
                this.shown = false
            }).catch(this.receive_error).then(() => {
                this.sending = false
            })
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        listingIds: {
            type: Array,
            required: true
        },
        contacts: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            data_version: 0,
            subject: "",
            message: "",
            to: [],
            sending: false
        }
    }
}
</script>

<style lang="scss">
#send-listings-dialog {
    trix-toolbar {
        .trix-button--icon-link,
        .trix-button-group--block-tools {
            display: none;
        }
    }
}
</style>
