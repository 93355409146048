<template>
    <modal-dialog
        id="feedback-editor-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <h5 v-if="!!record && !!record.id">
                {{ $root.translate("Feedback") }}
                <div v-if="!!current_listing" class="text-small text-gray">
                    {{ current_listing.computed_display_title }}<span v-if="record.on">&nbsp;&bull;&nbsp;{{ record.on | nibnut.date("MMM, DD") }} @ {{ record.on | nibnut.date("HH:mm") }}</span>
                </div>
            </h5>
            <span v-else class="h5">{{ $root.translate("New Feedback") }}</span>
        </template>

        <base-form
            v-if="!!record"
            :has-required-fields="true"
            @submit.prevent="create_or_save"
        >
            <form-select
                v-if="!record.listing_id"
                id="listing_id"
                name="listing_id"
                :value="record ? record.listing_id : 0"
                data-source="listing"
                :empty-value="0"
                :empty-label="$root.translate('MLS #, Property # or address')"
                label-field="computed_display_title"
                :filter-fields="['mls_id', 'id']"
                :show-all="true"
                :ad-hoc="false"
                :required="true"
                :error="has_error('listing_id')"
                @input="pick"
            >
                <template v-slot:label>{{ translate("Listing") }}</template>
                <template v-slot:option="{ option }">
                    <span v-if="!option.id">{{ option.computed_display_title }}</span>
                    <listing-compact-list-display
                        v-else
                        :record="option"
                    />
                </template>
            </form-select>
            <listing-compact-list-display
                v-if="!!current_listing && !!current_listing.id"
                :record="current_listing"
            />
            <div v-if="!!record" class="columns mt-4">
                <div class="column col-12">
                    <label class="form-label">{{ translate("Showing Date") }}</label>
                </div>
                <div
                    class="column"
                >
                    <form-date-input
                        id="day"
                        name="day"
                        :value="record.showed_at"
                        position="right"
                        :max="today"
                        calendar-size="sm"
                        :required="true"
                        :editable="is_at_least_realtor && !record.listing_showing_id"
                        :error="has_error('showed_at')"
                        @input="save_date"
                    />
                </div>
                <div class="column flex-static">
                    <label class="form-label">@</label>
                </div>
                <div
                    class="column"
                >
                    <form-input
                        id="showed_at"
                        name="showed_at"
                        :value="showed_at_time"
                        type="time"
                        :required="true"
                        :editable="is_at_least_realtor && !record.listing_showing_id"
                        @input="save_date"
                    />
                </div>
            </div>

            <form-rating-input
                id="rating"
                name="rating"
                v-model="record.rating"
                :required="true"
                :editable="is_at_least_realtor && !record.listing_showing_id"
                :error="has_error('rating')"
                class="mt-4"
                @input="save"
            >
                <template v-slot:label>
                    {{ $root.translate("Public Rating") }}
                </template>
            </form-rating-input>

            <form-textbox
                id="comments"
                name="comments"
                v-model="record.comments"
                size="md"
                :required="true"
                :editable="is_at_least_realtor"
                :error="has_error('comments')"
                @input="save"
                @click.native.stop
            >
                <template v-slot:label>
                    {{ $root.translate("Comments") }}
                </template>
                <template
                    v-slot:hint
                    v-if="!!record.original_comments && (record.original_comments !== record.comments)"
                >
                    {{ record.original_comments }}
                </template>
            </form-textbox>

            <form-toggle-input
                id="published_at"
                name="published_at"
                type="checkbox"
                :value="!!record.published_at"
                :editable="true"
                :disabled="!record.rating"
                :required="false"
                :error="has_error('published_at')"
                class="mt-2"
                @input="toggle_published_at"
                @click.native.stop
            >
                {{ translate("Published") }}
            </form-toggle-input>

            <h6 v-if="record.ratings" class="mt-2">{{ translate("Buyer Evaluation") }}</h6>
            <div v-if="record.ratings" class="columns">
                <feedback-rating
                    v-for="(rating, label) in record.ratings"
                    :key="label"
                    :label="label"
                    :rating="rating"
                    class="column col-6 col-sm-12"
                />
            </div>
        </base-form>
        <template v-if="!!record && !record.id" v-slot:footer>
            <div class="text-center">
                <default-button
                    class="mx-2"
                    @click.prevent="shown = false"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    type="submit"
                    form="showing-editor"
                    color="primary"
                    class="mx-2"
                    @click.prevent="create_or_save"
                >
                    {{ translate("Create") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDateInput from "@/nibnut/components/Inputs/FormDateInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import ListingCompactListDisplay from "@/custom/components/ListingCompactListDisplay"
import FormRatingInput from "@/custom/components/FormRatingInput"
import FeedbackRating from "@/custom/components/FeedbackRating"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "FeedbackEditorDialog",
    mixins: [handles_saving, addl_profile_utilities],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormDateInput,
        FormSelect,
        FormTextbox,
        ListingCompactListDisplay,
        FormRatingInput,
        FeedbackRating,
        FormToggleInput,
        DefaultButton
    },
    mounted () {
        this.set_current_listing()
    },
    watch: {
        listing: "set_current_listing"
    },
    methods: {
        set_current_listing () {
            this.current_listing = this.listing || this.current_listing
        },
        create_or_save () {
            if(this.record.id) this.shown = false
            else {
                if(!this.record.listing_id && this.current_listing && !!this.current_listing.id) this.record.listing_id = this.current_listing.id
                if(!this.record.showed_at && this.showed_at) this.record.showed_at = this.showed_at.format("YYYY-MM-DD")
                this.save_data_for_record_id(
                    this.entity,
                    0,
                    this.record
                ).then(record => {
                    if(record) {
                        this.shown = false
                        this.$emit("created", record)
                    }
                })
            }
        },
        pick (value, field, option) {
            this.current_listing = option
        },
        save_date (value, field) {
            const merge_date = () => {
                const existing_date = this.record.showed_at ? this.$dayjs(this.record.showed_at) : null
                if(existing_date) {
                    if(field === "day") {
                        value = value.millisecond(0)
                        value = value.second(0)
                        value = value.minute(existing_date.minute())
                        value = value.hour(existing_date.hour())
                    } else {
                        value = value.date(existing_date.date())
                        value = value.month(existing_date.month())
                        value = value.year(existing_date.year())
                    }
                }
                return value.format("YYYY-MM-DD HH:mm:ss")
            }
            if(field === "day") {
                value = this.$dayjs(value)
                this.record.showed_at = merge_date()
            } else {
                value = this.$dayjs(value, "HH:mm")
                this.record.showed_at = merge_date()
            }
            this.save(this.record.showed_at, "showed_at")
            if(field === "day") {
                const node = document.querySelector("#showed_at")
                if(node) node.focus()
            }
        },
        save (value, field) {
            this.record_id = this.record.id
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) this.save_field(this.record[field], field)
            }
        },
        toggle_published_at () {
            if(this.record.published_at) {
                this.record.published_at = null
                this.record.published_by = 0
            } else {
                this.record.published_at = this.$dayjs().format("YYYY-MM-DD HH:mm:ss")
                this.record.published_by = this.profile.id
            }
            if(this.record.id) {
                this.save_data_for_record_id(this.entity, this.record.id, {
                    published_at: this.record.published_at,
                    published_by: this.record.published_by
                })
            }
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        showed_at_time () {
            if(!!this.record && !!this.record.showed_at) return this.$dayjs(this.record.showed_at).format("HH:mm")
            return ""
        },
        today () {
            return new Date()
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        record: {
            type: Object,
            required: true
        },
        listing: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {
            entity: "listing_feedback",
            current_listing: null,
            showed_at: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#feedback-editor-dialog {
    .modal-body {
        padding-top: 0;
    }
}
</style>
