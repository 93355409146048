<template>
    <modal-dialog
        id="custom-date-range-dialog"
        :show.sync="shown"
    >
        <template v-slot:title>
            <span class="h5">{{ $root.translate("Custom Date Range") }}</span>
        </template>

        <base-calendar
            :selection="range"
            :max="today"
            :clickable="true"
            @click="set_range"
        />

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    class="mr-2"
                    @click.prevent="$emit('close')"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    class="ml-2"
                    @click.prevent="$emit('save')"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseCalendar from "@/nibnut/components/Inputs/BaseCalendar"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "CustomDateRangeDialog",
    components: {
        ModalDialog,
        BaseCalendar,
        DefaultButton
    },
    methods: {
        set_range (date) {
            if(!this.ranging) this.range.from = date
            else this.range.to = date

            if(this.range.from.isAfter(this.range.to)) {
                const date = this.range.to
                this.range.to = this.range.from
                this.range.from = date
            }

            this.ranging = !this.ranging
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        today () {
            return new Date()
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        range: {
            type: Object,
            validator: prop => !prop || (typeof prop === "object")
        }
    },
    data () {
        return {
            ranging: false // false = setting "from", else setting "to"
        }
    }
}
</script>
