<template>
    <modal-dialog
        id="lightbox"
        :show.sync="shown"
        :dismissable="true"
        class="modal-lg"
    >
        <template v-slot:title><span></span></template>
        <carousel
            id="lightbox"
            :images="images"
            :start-at="startAt"
            :track="true"
            :track-value="trackValue"
        />
    </modal-dialog>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import Carousel from "@/custom/components/Carousel"

export default {
    name: "Lightbox",
    components: {
        ModalDialog,
        Carousel
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                if(!show) this.$store.dispatch("LIGHTBOX", {})
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        images: {
            type: Array,
            required: true
        },
        startAt: {
            type: Number,
            default: 0
        },
        trackValue: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";

#lightbox {
    .modal-container {
        background: $dark-color;
        .modal-header {
            flex: 0 0 auto;
            .modal-title, .btn.btn-clear {
                color: $light-color;
            }
        }
        .modal-body {
            padding: 0;
            flex: 1 1 auto;
            display: flex;

            .carousel-container {
                text-align: center;

                img, img.img-responsive {
                    display: inline-block;
                    max-width: 100%;
                    max-height: 100%;
                }
                & > img {
                    visibility: hidden;
                }
                .carousel {
                    height: 100%;

                    figure {
                        background: $dark-color;
                        img {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }

                &.bootstrapping {
                    & > img {
                        visibility: visible;
                    }
                }
            }
        }
    }
    &:target,
    &.active {
        .modal-overlay {
            background: $dark-color;
        }
    }
    z-index: $zindex-lightbox;
}
</style>
